import { Directive, HostListener } from '@angular/core';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({
    selector: '[save-no-dbl-click]',
})
export class SaveNoDblClickDirective {
    constructor() {
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        let element: any;
        if (event.target.localName === 'button') {
            element = event.target;
        } else {
            element = event.target.offsetParent;
        }
        element.disabled = true;
        setTimeout(() => {
            element.disabled = false;
        }, 1000);
    }
}
