import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { EUserType } from '../../enums/EUserType';
import { AuthService } from '../../services/models/auth.service';
import { UserInfoFormModelC } from '../user-info-form-model/user-info-form-model.c';
import { EFormMode } from '../../enums/EFormMode';
import { notificationsFormModelC } from './notifications-modal/notifications-form-model.c';
import { INotification, INotification_P, INotification_S } from '../../interfaces/INotification';
import { SocketService } from '../../services/socket.service';
import { ConstantsService } from '../../services/constants.service';
import { NotificationService } from '../../services/notification.service';
import { RbNotificationService } from '../../services/models/rb-notification.service';
import { ISubscription } from 'rxjs-compat/Subscription';
import { IQueryFormat } from '../../enums/types';
import { UtilsService } from '../../services/utils.service';
import { ISignUp_P, ISignUp_S } from '../../interfaces/ISignUp';
import { CustomerService } from '../../services/models/customer.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    loading = { form: false };
    EUserType = EUserType;
    baseNameList = [];
    subscriptions: ISubscription[] = [];
    notifications: INotification[] = [];
    countNotification: number;

    @ViewChild('userInfoFormModelC', { static: false }) userInfoFormModelC: UserInfoFormModelC;
    @ViewChild('notificationsFormModelC', { static: false }) notificationsFormModelC: notificationsFormModelC;

    constructor(public authService: AuthService,
                public socketService: SocketService,
                public constantsService: ConstantsService,
                public utilsService: UtilsService,
                public customerService: CustomerService,
                public rbNotificationService: RbNotificationService,
                public ns: NotificationService,
                public cd: ChangeDetectorRef) {
    }

    async ngOnInit() {
        await this.loadBaseName();

        const navbarMenu = document.getElementById("navbar");
        const burgerMenu = document.getElementById("burger");
        const overlayMenu = document.querySelector(".overlay");

// Show and Hide Navbar Function
        const toggleMenu = () => {
            navbarMenu.classList.toggle("active");
            overlayMenu.classList.toggle("active");
        };

// Collapsible Mobile Submenu Function
        const collapseSubMenu = () => {
            navbarMenu
                .querySelector(".menu-dropdown.active .submenu")
                .removeAttribute("style");
            navbarMenu.querySelector(".menu-dropdown.active").classList.remove("active");
        };

// Toggle Mobile Submenu Function
        const toggleSubMenu = (e) => {
            if (e.target.hasAttribute("data-toggle") && window.innerWidth <= 992) {
                e.preventDefault();
                const menuDropdown = e.target.parentElement;

                // If Dropdown is Expanded, then Collapse It
                if (menuDropdown.classList.contains("active")) {
                    collapseSubMenu();
                } else {
                    // Collapse Existing Expanded Dropdown
                    if (navbarMenu.querySelector(".menu-dropdown.active")) {
                        collapseSubMenu();
                    }

                    // Expanded the New Dropdown
                    menuDropdown.classList.add("active");
                    const subMenu = menuDropdown.querySelector(".submenu");
                    subMenu.style.maxHeight = subMenu.scrollHeight + "px";
                }
            }
        };

// Fixed Resize Window Function
        const resizeWindow = () => {
            if (window.innerWidth > 992) {
                if (navbarMenu.classList.contains("active")) {
                    toggleMenu();
                }
                if (navbarMenu.querySelector(".menu-dropdown.active")) {
                    collapseSubMenu();
                }
            }
        };

// Initialize Event Listeners
        burgerMenu.addEventListener("click", toggleMenu);
        overlayMenu.addEventListener("click", toggleMenu);
        navbarMenu.addEventListener("click", toggleSubMenu);
        window.addEventListener("resize", resizeWindow);

        this.subscriptions.push(this.socketService.events.notification.subscribe(async (notification: INotification) => {
            if (notification.userId) {
                // console.log(notification);
                if (notification.type !== 'REFRESH_UI') { // REFRESH_UI will be removed.
                    // await new Audio('/assets/music/notification.mp3').play();
                    this.countNotification += 1;
                    this.notifications.unshift(notification);
                    this.ns.showInfo(notification.message);
                    if (this.notifications.length > 30) this.notifications.splice(-1, 1);
                }
            }
            this.cd.detectChanges();
        }));
        this.subscriptions.push(this.constantsService.notification.refreshNotification.subscribe(async (data: INotification) => {
            if (!data) return;
            await this.loadNotificationsData();
            this.cd.detectChanges();
        }));
        this.socketService.getConnection(this.authService.getUserId());
        await this.loadNotificationsData();
        this.cd.detectChanges();
    }

    userInfoShowModel() {
        this.userInfoFormModelC.showModal(EFormMode.EDIT, this.authService.gUser);
    }

    ViewAllNotificationsModelOpen(event) {
        event.stopPropagation();
        this.notificationsFormModelC.showModal();
    }


    async markAsAllRead(event) {
        event.stopPropagation();
        this.constantsService.notificationMarkAsReadAll.next(this.authService.gUser);
    }

    async loadNotificationsData() {
        this.notifications = [];
        const query: IQueryFormat = {
            find: <INotification_P>{
                userId: this.authService.getUserId(),
                active: true,
            },
            select: <INotification_S>{
                message: 1,
                userId: 1,
                createdAt: 1,
                isRead: 1,
            },
            sort: <INotification_S>{ createdAt: -1 },
            limit: 30,
        };
        this.notifications = await this.rbNotificationService.query(query);
        this.countNotification = await this.rbNotificationService.countNotification({ find: <INotification_P>{ userId: this.authService.getUserId(), isRead: false, active: true } });
        this.cd.detectChanges();
    }

    async loadBaseName() {
        this.loading.form = true;
        const query: IQueryFormat = {
            find: <ISignUp_P>{ active: true, billerCanBill: <any>{ $in: [this.authService.gUser._id] } },
            select: <ISignUp_S>{ baseName: 1, isBillerClient: 1 },
            sort: <ISignUp_S>{ _id: 1 },
        };
        if (this.authService.gUser.userType === EUserType.CLIENT) {
            query.find._id = this.authService.gUser.companyId;
            delete query.find.billerCanBill;
        }
        if (this.authService.gUser.userType === EUserType.ADMIN) delete query.find.billerCanBill;
        const resp = await this.customerService.query(query);
        if (resp) this.baseNameList = resp;
        this.loading.form = false;
        this.cd.detectChanges();
    }

    async clearBase() {
        this.constantsService.chooseBase = <any>{};
        this.loadBaseNameDate();
    }

    async loadBaseNameDate() {
        this.constantsService.baseNameDropDown.baseNameChange.emit(Math.random());
        this.cd.detectChanges();
    }
}
