import { EventEmitter, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EConfirmationDialogStatus } from '../enums/EConfirmationDialogStatus';

declare let iziToast: any;

/*
* https://izitoast.marcelodolza.com/
*/
@Injectable({ providedIn: 'root' })
export class NotificationService {
    public dialog = {
        confirm: {
            message: '',
            confirmationDialogOpened: false,
            confirmationDialogOpenedOK: false,
            confirmationDialogStatus: new EventEmitter<EConfirmationDialogStatus>(),
            confirmationDialogOpenedUserInput: false,
            confirmationDialogStatusUserInput: new EventEmitter(),
        },
    };

    constructor() {
    }

    showSuccess(message: string) {
        iziToast.success({ message: message });
    }

    showError(message: string) {
        iziToast.error({ message: message });
    }

    showWarning(message: string) {
        iziToast.warning({ message: message });
    }

    showInfo(message: string) {
        iziToast.info({ message: message });
    }

    showErrorFromResponse(res: any, defaultResponse: string) {
        let message;
        if (res && res.errors && res.errors.length) message = (<any>res.errors[0]).reason || res.errors[0].message;
        else {
            const err: Error = <any>res;
            console.error(err);
            message = err.message;
        }
        if (!message) message = _.get(res, 'error.message');
        this.showError(message || defaultResponse);
    }
}
