import { Pipe, PipeTransform } from '@angular/core';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Pipe({
    name: 'numberWithComma',
})
export class NumberWithCommaPipe implements PipeTransform {
    constructor() {
    }

    /**
     * It will generate comma separated string with proper dots at the end.
     * It will make 2.3 to 2.30 automatically.
     */
    transform(value: any, args?: any): any {
        if (typeof value === 'number' && value) {
            if (value.toString().indexOf('.') >= 0) {
                const valueTemp = Number(value.toFixed(2));
                let returnVal = Number(valueTemp.toString()).toLocaleString('en-IN');
                const arr = returnVal.split('.');
                if (arr && arr[1] && arr[1].length === 1) returnVal = returnVal + '0';
                return returnVal;
            } else return Number(value.toString()).toLocaleString('en-IN') + '.00';
        } else {
            return '0.00';
        }
    }
}
