import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ECollection } from '../../enums/ECollection';
import { EModelApiPaths } from '../../enums/EModelApiPaths';
import { EAuthTokenType, EGetEncryptedData, EHeader, IAPIResponse, IDecryptDataFETransfer, IDecryptDataFETransferPayload, IQueryFormat } from '../../enums/types';
import { IUser, IUser_P } from '../../interfaces/IUser';
import { ConstantsService } from '../constants.service';
import { UtilsService } from '../utils.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
    amUser: {
        accessAMToken: '',
        refreshAMToken: '',
    };

    gUser = new Subject<any>();

    constructor(public http: HttpClient, public constantsService: ConstantsService, public utilsService: UtilsService, public authService: AuthService) {
        super(http, <any>null, utilsService, `/schema/${environment.USER_PATH}/${environment.INS}/${environment.DB}/${ECollection.USERS}`);
        this.getThis().userService = this.userService;

        this.gUser.subscribe(async (data) => {
            if (!data) return;
            const tempUser: IUser_P = <any>this.authService.gUser;
            if (data.action === 'REFRESH_G_USER') {
                const userInfoRes = await this.loadGUserInfo(tempUser._id);
                this.authService.setUserToLocalStorage(userInfoRes[0], environment.crypto.passJWT);
            }
        });
    }

    async getAMToken() {
        const encryptedPayload = this.utilsService.getEncryptedString(<IDecryptDataFETransferPayload>{
            data: this.constantsService.authTokenAM,
            createdAt: new Date(),
        }, environment.crypto.passJWT);
        const res: IAPIResponse<IUser> = <any>await this.http.post(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.SYSTEM_API_BASE + `/${environment.USER_PATH}/token`,
            <IDecryptDataFETransfer>{ dataEncFE: encryptedPayload }, {
                headers: {
                    [EHeader.ENCRYPTED_PAYLOAD]: 'true',
                },
            }).toPromise();
        return res.data;
    }

    async getAMAdminAndRootToken(dataItems) {
        const encryptedPayload = this.utilsService.getEncryptedString(<IDecryptDataFETransferPayload>{
            data: dataItems,
            createdAt: new Date(),
        }, environment.crypto.passJWT);
        const res: IAPIResponse<IUser> = <any>await this.http.post(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.SYSTEM_API_BASE + `/${environment.USER_PATH}/token`,
            <IDecryptDataFETransfer>{ dataEncFE: encryptedPayload }, {
                headers: {
                    [EHeader.ENCRYPTED_PAYLOAD]: 'true',
                },
            }).toPromise();
        return res.data;
    }

    async login(email: string, password: string) {
        const amToken = <any>await this.getAMToken();
        if (amToken) {
            const encryptedPayload = <IDecryptDataFETransfer>{
                dataEncFE: this.utilsService.getEncryptedString(<IDecryptDataFETransferPayload>{
                    data: {
                        email,
                        password,
                    },
                    createdAt: new Date(),
                }, environment.crypto.passJWT),
            };
            const resp: IAPIResponse<IUser> = <any>await this.http.post(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.USER_LOGIN, encryptedPayload, {
                headers: {
                    [EHeader.AUTHORIZATION_AM]: amToken.token,
                    [EHeader.ENCRYPTED_PAYLOAD]: 'true',
                    [EHeader.GET_ENCRYPTED_DATA]: EGetEncryptedData.get_only_encryption,
                },
            }).toPromise();
            const dataItem = this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
            const amAdminRootToken = <any>await this.getAMAdminAndRootToken(this.constantsService.authTokenAM);
            this.setAMUserToLocalStorage({
                accessAMToken: amAdminRootToken.token,
                refreshAMToken: amAdminRootToken.refresh_token,
            }, environment.crypto.passJWT);
            return dataItem;
        }
    }

    async saveUser<T>(user: IUser_P) {
        const amToken = <any>await this.getAMToken();
        if (amToken) {
            const encryptedData = this.encryptedPayload(user);
            const resp: IAPIResponse<IUser_P> = <any>await this.http.post(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/save-single-or-multiple`, encryptedData, this.getRequestOptions()).toPromise();
            this.setAMUserToLocalStorage({
                accessAMToken: amToken.token,
                refreshAMToken: amToken.refresh_token,
            }, environment.crypto.passJWT);
            if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
            else return resp.data;
        }
    }

    async getByIdUser<T>(id: string): Promise<T> {
        const resp: IAPIResponse<any> = <any>await this.http.get<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/get-by-id/${id}`, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async refreshToken() {
        if (this.authService.gUser && this.authService.gUser._id) {
            const authTokenAM = {
                authTokenType: EAuthTokenType.AM,
                authTokenAM: { refresh_token: this.amUser.refreshAMToken },
            };
            const authTokenAMDB = {
                authTokenType: EAuthTokenType.AM_DB,
                authTokenAMDB: { refresh_token: this.authService.token.refreshToken },
            };
            const res: IAPIResponse<IUser> = <any>await this.http.post(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.SYSTEM_API_BASE + `/${environment.USER_PATH}/token`, [authTokenAM, authTokenAMDB]).toPromise();

            if (!res.data[0] || !res.data[1]) return this.authService.logout();

            // AM User
            const amUser = this.amUser;
            amUser.accessAMToken = <any>res.data[0].token;
            amUser.refreshAMToken = <any>res.data[0].refresh_token;
            this.setAMUserToLocalStorage(amUser, environment.crypto.passJWT);

            // AM_DB user
            const user: IUser = this.authService.gUser;
            this.authService.token.accessToken = res.data[1].token;
            this.authService.token.refreshToken = res.data[1].refresh_token;
            this.authService.setUserToLocalStorage(user, environment.crypto.passJWT);
            // await this.updateUser<IUser>(user._id, { lastLoginTime: new Date() }, {});
        }
    }

    setAMUserToLocalStorage(userObj: any, p: string) {
        this.amUser = userObj;
        const encryptedCode = this.utilsService.getEncryptedString(this.amUser, p);
        localStorage.setItem('AM_USER', encryptedCode);
    }

    getAMUserFromLocalStorage(p: string) {
        const value = localStorage.getItem('AM_USER');
        if (value) {
            const decryptedObj = this.utilsService.getDecryptedObject(value, p);
            if (decryptedObj) return decryptedObj;
            else localStorage.setItem('AM_USER', '');
        }
    }

    getRequestOptions() {
        return {
            headers: {
                [EHeader.AUTHORIZATION_AM]: this.amUser.accessAMToken,
                [EHeader.AUTHORIZATION_AM_USER]: this.authService.token.accessToken,
                [EHeader.ENCRYPTED_PAYLOAD]: environment.IS_ENCRYPTED ? 'true' : 'false',
                [EHeader.GET_ENCRYPTED_DATA]: environment.IS_ENCRYPTED
                    ? EGetEncryptedData.get_only_encryption : EGetEncryptedData.no_encryption,
                [EHeader.SANDBOX_TIMEOUT]: (30 * (1000 * 60)).toString(), // 10 minutes
            },
        };
    }

    loadSettings() { // settingsType = root | admin | dev
        return new Promise<void>(async (resolve, reject) => {
            resolve();
        });
    }

    async loadGUserInfo(id: string) {
        const findString = JSON.stringify({ _id: id });
        const resp: IAPIResponse<IUser> = <any>await this.http.get(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}?find=` + findString, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async getAllAdminUsers(userType: string) {
        const findString = JSON.stringify({ userType });
        const resp: IAPIResponse<IUser[]> = <any>await this.http.get<IUser[]>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}?find=` + findString, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async amUserQuery<T>(body: IQueryFormat, token?: string) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = <any>await this.http.post<T[]>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/query`, encryptedData, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async updateUser<T>(id: string, body: Partial<T>, queryParams?: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = <any>await this.http.put<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/update-by-id/${id}`, encryptedData, {
            params: queryParams,
            headers: this.getRequestOptions().headers,
        }).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async saveAdminUserInRootSide<T>(user: IUser) {
        const encryptedData = this.encryptedPayload(user);
        const resp: IAPIResponse<any> = <any>await this.http.post<T[]>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/save-single-or-multiple`, encryptedData, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async permanentDeleteUser<T>(id: string) {
        const resp: IAPIResponse<any> = <any>await this.http.delete<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/${id}`, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async permanentDeleteUserByRootUserOnly<T>(id: string) {
        const resp: IAPIResponse<any> = <any>await this.http.delete<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/${id}`, this.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }
}
