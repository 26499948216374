export enum EModelApiPaths {
    API = '/api',
    CUSTOM_API_BASE = '/custom-api',
    SYSTEM_API_BASE = '/system-api',

    // user
    USER_LOGIN = '/billing/rocket-billing-login',
    MAS_VENDOR_FILE_UPLOAD = '/billing/mas/vendor-upload',
    MAS_DRIVER_FILE_UPLOAD = '/billing/mas/driver-upload',
    MAS_ROSTER_FILE_UPLOAD = '/billing/mas/roster-upload',
    SYNC_DRIVER_LIST = '/billing/mas/api/get-driver-list',
    SYNC_VEHICLE_LIST = '/billing/mas/api/get-vehicle-list',
    GET_ROSTER_DATA = '/billing/mas/api/get-roster-data',
    MAS_ATTEST_INVOICE_SUBMIT = '/billing/mas/api/attest-invoice-submit',
    MAS_TRANSPORTATION_PROVIDER_INFO = '/billing/mas/api/transportation-provider-info',
    MAS_SYNC_ALL_BASE_DRIVER_AND_VEHICLE = '/billing/mas/api/sync_all_base_driver_and_vehicle',
    MAS_SET_DRIVER_VEHICLE = '/billing/mas/api/set-driver-vehicle',

    // EMEDNY
    EMEDNY_CLAIM = '/billing/emedny/claim',
    EMEDNY_SUMMARY_REPORTS = '/billing/emedny/summary-reports',
    EMEDNY_REPORTS_DETAILS = '/billing/emedny/report-details',
    EMEDNY_ELIGIBILITY_REPORTS = '/billing/emedny/eligibility-reports',
    CLAIM_TRACKINGS_DOWNLOAD_FILE = '/billing/emedny/claim-trackings-download-file',
    CLAIM_RE_SUBMIT_837_FILE = '/billing/emedny/claim-re-submit-837-file',
    CLAIM_RE_SUBMIT_276_FILE = '/billing/emedny/claim-re-submit-276-file',

    SAVE_PAYMENT = '/billing/emedny/save-payment',
    UPDATE_PAYMENT = '/billing/emedny/update-payment',
    DELETE_PAYMENT_AND_INVOICES = '/billing/emedny/delete-payment-and-invoices',

    INVOICES_CREATED = '/billing/emedny/create-invoice',
    INVOICES_DELETE = '/billing/emedny/delete-invoice',
    INVOICE_SEND_REMINDER_MAIL_TO_CLIENT = '/billing/emedny/invoice-send-reminder-mail-to-client',
    INVOICE_SEND_MAIL_TO_CLIENT = '/billing/emedny/invoice-send-mail-to-client',

    GET_LEGS_FROM_MAS_BASED_ON_EMEDNY_CORRECTION_FILE = '/billing/emedny/get-legs-from-mas-based-on-emedny-correction-file',

    // stripe
    STRIPE_BASE = 'billing/stripe',
    CREATE_CHECKOUT_SESSION = '/create-checkout-session',
    CHECKOUT_SESSIONS_RETRIEVE = '/checkout-sessions-retrieve',
    GET_PAYMENT_METHOD_LIST = '/get-payment-method-list',
}
