import { IModelBase } from './IModelBase';

export interface INotification extends IModelBase {
    type: string;
    isRead: boolean;
    message: string;
    userId: string;
}

export type INotification_P = Partial<INotification>;
export type INotification_S = { [Property in keyof INotification_P]: 1 | -1 };


export enum ENotificationType {
    PA_ROSTER_FILE_UPLOAD = 'PA_ROSTER_FILE_UPLOAD',
    DRIVER_VEHICLE_FILE_UPLOAD = 'DRIVER_VEHICLE_FILE_UPLOAD',
    SYNC_DRIVERS = 'SYNC_DRIVERS',
    SYNC_VEHICLES = 'SYNC_VEHICLES',
    SYNC_ALL_BASE_DRIVER_AND_VEHICLE = 'SYNC_ALL_BASE_DRIVER_AND_VEHICLE',
    REFRESH_UI = 'REFRESH_UI',
    FILE_RECEIVED277_90001 = 'FILE_RECEIVED277_90001',
}
