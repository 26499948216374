import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IAPIResponse, IDecryptDataFETransfer, IDecryptDataFETransferPayload, IGetAllQueryParams, IQueryFormat } from '../../enums/types';
import { UtilsService } from '../utils.service';
import { UserService } from './user.service';

export class BaseService {
    modelPath: string = '';
    isEncrypted = environment.IS_ENCRYPTED;

    constructor(
        public http: HttpClient,
        public userService: UserService,
        public utilsService: UtilsService,
        modelPath: string) {
        this.modelPath = modelPath;
    }

    getThis() {
        return this;
    }

    encryptedPayload(body: any) {
        let encryptedPayload;
        if (this.isEncrypted) {
            encryptedPayload = <IDecryptDataFETransfer>{
                dataEncFE: this.utilsService.getEncryptedString(<IDecryptDataFETransferPayload>{
                    data: body,
                    createdAt: new Date(),
                }, environment.crypto.passJWT),
            };
        } else encryptedPayload = body;
        return encryptedPayload;
    }

    async query<T>(body: IQueryFormat) {
        const options: any = this.userService.getRequestOptions();
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<T> = <any>await this.http.post<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/query`, encryptedData, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async queryOnlyActive<T>(select?: any) {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.post<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/query`, <IQueryFormat>{
            find: { active: 1 },
            select,
        }, options).toPromise();
        return resp.data;
    }

    async post<T>(body: T) {
        const options: any = this.userService.getRequestOptions();
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = <any>await this.http.post<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/save-single-or-multiple`, encryptedData, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async put<T>(id: string, body: Partial<T>, queryParams?: any) {
        const options: any = this.userService.getRequestOptions();
        const encryptedData = this.encryptedPayload(body);
        options.params = queryParams;
        const resp: IAPIResponse<any> = <any>await this.http.put<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/update-by-id/${id}`, encryptedData, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async getAll<T>() {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.get<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}`, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async countFromQuery(query) {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.post(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/count`, query, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async getById<T>(id: string): Promise<T> {
        const resp: IAPIResponse<any> = <any>await this.http.get<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/get-by-id/${id}`, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async getByIdWithSelect<T>(id: string, select: { [Property in keyof Partial<T>]: 1 | -1 }): Promise<T> {
        const options: any = this.userService.getRequestOptions();
        options.params = <IGetAllQueryParams>{
            select: JSON.stringify(select),
        };
        const resp: IAPIResponse<any> = <any>await this.http.get<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/get-by-id/${id}`, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async softDelete<T>(id: string) {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.put<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/${id}`, { active: false }, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async makeActive<T>(id: string) {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.put<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/${id}`, { active: true }, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async permanentDelete<T>(id: string) {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.delete<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/${id}`, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async softDeleteNested<T>(id: string) {
        const options: any = this.userService.getRequestOptions();
        const resp: IAPIResponse<any> = <any>await this.http.put<T>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/delete_soft/${id}`, { id }, options).toPromise();
        return resp.data;
    }

    async distinct_field(query: any, field: string, order: string) {
        const options: any = this.userService.getRequestOptions();
        return this.http.post(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/distinct/${field}/${order}`, query, options);
    }

    async removeByQuery<T>(query) {
        const options: any = this.userService.getRequestOptions();
        const encryptedData = this.encryptedPayload(query);
        const resp: IAPIResponse<any> = <any>await this.http.post<IAPIResponse<T>>(environment.RB_BE_HOST_PORT_SITES + `${this.modelPath}/query/delete`, encryptedData, options).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }
}
