import { Injectable } from '@angular/core';
import { EBroker } from '../enums/EBroker';
import { EServiceFrequency } from '../enums/EServiceFrequency';
import { EInvoiceStatus } from '../enums/EInvoiceStatus';

@Injectable({ providedIn: 'root' })
export class DataProviderService {
    constructor() {
    }

    EDIDataList() {
        return [
            {
                id: 'EDI_837',
                name: 'EDI 837',
            },
            {
                id: 'EDI_276',
                name: 'EDI 276',
            },
            {
                id: 'EDI_270',
                name: 'EDI 270',
            },
        ];
    }

    statusList() {
        return [
            {
                id: 'STATUS',
                name: 'Status',
            },
            {
                id: 'CLAIMED',
                name: 'Claimed',
            },
            {
                id: 'NOT_CLAIMED',
                name: 'Not Claimed',
            },
        ];
    }

    tripStatus() {
        return [
            {
                id: '0',
                name: 'Trip Took Place',
            },
            {
                id: '1',
                name: 'Cancelled',
            },
            {
                id: '2',
                name: 'No Show',
            },
        ];
    }

    eligibilityActiveList() {
        return [
            {
                id: 'ALL',
                name: 'All',
            },
            {
                id: 'ACTIVE',
                name: 'Active',
            },
            {
                id: 'INACTIVE',
                name: 'Inactive',
            },
            {
                id: 'PENDING',
                name: 'Pending',
            },
        ];
    }

    signOffStatusList() {
        return [
            {
                id: 'STATUS',
                name: 'Status',
            },
            {
                id: 'ATTESTED',
                name: 'Attested',
            },
            {
                id: 'NOT_ATTESTED',
                name: 'Not Attested',
            },
        ];
    }

    correctionStatusList() {
        return [
            {
                id: 'ALL',
                name: 'All',
            },
            {
                id: 'CORRECTION',
                name: 'Correction',
            },
            {
                id: 'UNCORRECTION',
                name: 'Uncorrection',
            },
        ];
    }

    lookBackStatusList() {
        return [
            {
                id: 'ALL',
                name: 'All',
            },
            {
                id: 'PAID',
                name: 'Paid',
            },
            {
                id: 'UNPAID',
                name: 'Unpaid',
            },
        ];
    }

    reportTypes() {
        return [
            {
                id: 'CLAIM_REPORT',
                name: 'Claim Report',
            },
            {
                id: 'LOOK_BACK_REPORT',
                name: 'Look Back Report',
            },
        ];
    }

    reportStatus() {
        return [
            {
                id: 'ALL',
                name: 'All',
            },
            {
                id: 'DENIED',
                name: 'Denied',
            },
            {
                id: 'APPROVED',
                name: 'Approved',
            },
            {
                id: 'PENDING',
                name: 'Pending',
            },
        ];
    }

    getBroker() {
        return [
            // {
            //     id: EBroker.ALL,
            //     name: EBroker.ALL,
            // },
            {
                id: EBroker.MAS,
                name: EBroker.MAS,
            },
            // {
            //     id: EBroker.LOG,
            //     name: EBroker.LOG,
            // },
        ];
    }

    getUploadFor() {
        return [
            // {
            //     id: 'VENDOR',
            //     name: 'Vendor',
            //     type: EBroker.MAS,
            // },
            {
                id: 'PA_ROSTER',
                name: 'PA Roster',
                type: EBroker.MAS,
            },
            {
                id: 'DRIVER_VEHICLE',
                name: 'Driver & Vehicle',
                type: EBroker.MAS,
            },
            // {
            //     id: 'VEHICLE',
            //     name: 'Vehicle',
            //     type: EBroker.MAS,
            // },
            // {
            //     id: 'LIMO_TOTAL_JOB',
            //     name: 'Limo Total Job',
            //     type: EBroker.MAS,
            // },
            // {
            //     id: 'OPERR_TOTAL_JOBS',
            //     name: 'Operr Total Jobs',
            //     type: EBroker.MAS,
            // },
            // {
            //     id: 'DRIVER',
            //     name: 'Driver',
            //     type: EBroker.MAS,
            // // },
            // {
            //     id: 'EPACE_PA',
            //     name: 'Epace PA',
            //     type: EBroker.ALL,
            // },
            // {
            //     id: 'PA',
            //     name: 'PA',
            //     type: EBroker.LOG,
            // },
            // {
            //     id: 'VENDOR_EXCEL',
            //     name: 'Vendor (Excel)',
            //     type: EBroker.LOG,
            // },
            // {
            //     id: 'VENDOR_PDF',
            //     name: 'Vendor (PDF)',
            //     type: EBroker.LOG,
            // },
            // {
            //     id: 'TOTAL_JOB',
            //     name: 'Total Job',
            //     type: EBroker.LOG,
            // },
            // {
            //     id: 'LOS_PAYMENT',
            //     name: 'Los Payment',
            //     type: EBroker.LOG,
            // },
        ];
    }

    getServiceFrequency() {
        return [
            {
                id: EServiceFrequency.DAILY,
                name: EServiceFrequency.DAILY,
            },
            {
                id: EServiceFrequency.WEEKLY,
                name: EServiceFrequency.WEEKLY,
            },
            {
                id: EServiceFrequency.MONTHLY,
                name: EServiceFrequency.MONTHLY,
            },
        ];
    }

    driverVehicleStatus() {
        return [
            {
                id: '0',
                name: 'inActive',
            },
            {
                id: '1',
                name: 'Active',
            },
        ];
    }


    invoiceStatus() {
        return [
            {
                id: EInvoiceStatus.ACTIVE,
                name: EInvoiceStatus.ACTIVE,
            },
            {
                id: EInvoiceStatus.INACTIVE,
                name: EInvoiceStatus.INACTIVE,
            }
            // {
            //     id: EInvoiceStatus.PARTIAL_PAID,
            //     name: EInvoiceStatus.PARTIAL_PAID,
            // },
            // {
            //     id: EInvoiceStatus.PAID,
            //     name: EInvoiceStatus.PAID,
            // },
        ];
    }

    vehicleType() {
        return [
            {
                id: 'Ambulance',
                name: 'Ambulance',
            },
            {
                id: 'Taxi',
                name: 'Taxi',
            },
            {
                id: 'Stretcher',
                name: 'Stretcher',
            },
            {
                id: 'Livery',
                name: 'Livery',
            },
            {
                id: 'Personal Car',
                name: 'Personal Car',
            },
            {
                id: 'Wheelchair',
                name: 'Wheelchair',
            },
            {
                id: 'Specialty',
                name: 'Specialty',
            },
        ];
    }

    getUSAStateList() {
        return [
            {
                id: 'AL',
                name: 'Alabama',
            },
            {
                id: 'AK',
                name: 'Alaska',
            },
            {
                id: 'AZ',
                name: 'Arizona',
            },
            {
                id: 'AR',
                name: 'Arkansas',
            },
            {
                id: 'CA',
                name: 'California',
            },
            {
                id: 'CO',
                name: 'Colorado',
            },
            {
                id: 'CT',
                name: 'Connecticut',
            },
            {
                id: 'DE',
                name: 'Delaware',
            },
            {
                id: 'DC',
                name: 'District of Columbia',
            },
            {
                id: 'FL',
                name: 'Florida',
            },
            {
                id: 'GA',
                name: 'Georgia',
            },
            {
                id: 'HI',
                name: 'Hawaii',
            },
            {
                id: 'ID',
                name: 'Idaho',
            },
            {
                id: 'IL',
                name: 'Illinois',
            },
            {
                id: 'IN',
                name: 'Indiana',
            },
            {
                id: 'IA',
                name: 'Iowa',
            },
            {
                id: 'KS',
                name: 'Kansas',
            },
            {
                id: 'KY',
                name: 'Kentucky',
            },
            {
                id: 'LA',
                name: 'Louisiana',
            },
            {
                id: 'ME',
                name: 'Maine',
            },
            {
                id: 'MD',
                name: 'Maryland',
            },
            {
                id: 'MA',
                name: 'Massachusetts',
            },
            {
                id: 'MI',
                name: 'Michigan',
            },
            {
                id: 'MN',
                name: 'Minnesota',
            },
            {
                id: 'MS',
                name: 'Mississippi',
            },
            {
                id: 'MO',
                name: 'Missouri',
            },
            {
                id: 'MT',
                name: 'Montana',
            },
            {
                id: 'NE',
                name: 'Nebraska',
            },
            {
                id: 'NV',
                name: 'Nevada',
            },
            {
                id: 'NH',
                name: 'New Hampshire',
            },
            {
                id: 'NJ',
                name: 'New Jersey',
            },
            {
                id: 'NM',
                name: 'New Mexico',
            },
            {
                id: 'NY',
                name: 'New York',
            },
            {
                id: 'NC',
                name: 'North Carolina',
            },
            {
                id: 'ND',
                name: 'North Dakota',
            },
            {
                id: 'OH',
                name: 'Ohio',
            },
            {
                id: 'OK',
                name: 'Oklahoma',
            },
            {
                id: 'OR',
                name: 'Oregon',
            },
            {
                id: 'PA',
                name: 'Pennsylvania',
            },
            {
                id: 'RI',
                name: 'Rhode Island',
            },
            {
                id: 'SC',
                name: 'South Carolina',
            },
            {
                id: 'SD',
                name: 'South Dakota',
            },
            {
                id: 'TN',
                name: 'Tennessee',
            },
            {
                id: 'TX',
                name: 'Texas',
            },
            {
                id: 'UT',
                name: 'Utah',
            },
            {
                id: 'VT',
                name: 'Vermont',
            },
            {
                id: 'VA',
                name: 'Virginia',
            },
            {
                id: 'WA',
                name: 'Washington',
            },
            {
                id: 'WV',
                name: 'West Virginia',
            },
            {
                id: 'WI',
                name: 'Wisconsin',
            },
            {
                id: 'WY',
                name: 'Wyoming',
            },
        ];
    };

    getWeeklyList() {
        return [
            {
                id: 'SUNDAY',
                name: 'Sunday',
            },
            {
                id: 'MONDAY',
                name: 'Monday',
            },
            {
                id: 'TUESDAY',
                name: 'Tuesday',
            },
            {
                id: 'WEDNESDAY',
                name: 'Wednesday',
            },
            {
                id: 'THURSDAY',
                name: 'Thursday',
            },
            {
                id: 'FRIDAY',
                name: 'Friday',
            },
            {
                id: 'SATURDAY',
                name: 'Saturday',
            },
        ];
    };

    getMonthDaysList() {
        return [
            {
                id: 1,
                name: 1,
            },
            {
                id: 2,
                name: 2,
            },
            {
                id: 3,
                name: 3,
            },
            {
                id: 4,
                name: 4,
            },
            {
                id: 5,
                name: 5,
            },
            {
                id: 6,
                name: 6,
            },
            {
                id: 7,
                name: 7,
            },
            {
                id: 8,
                name: 8,
            },
            {
                id: 9,
                name: 9,
            },
            {
                id: 10,
                name: 10,
            },
            {
                id: 11,
                name: 11,
            },
            {
                id: 12,
                name: 12,
            },
            {
                id: 13,
                name: 13,
            },
            {
                id: 14,
                name: 14,
            },
            {
                id: 15,
                name: 15,
            },
            {
                id: 16,
                name: 16,
            },
            {
                id: 17,
                name: 17,
            },
            {
                id: 18,
                name: 18,
            },
            {
                id: 19,
                name: 19,
            },
            {
                id: 20,
                name: 20,
            },
            {
                id: 21,
                name: 21,
            },
            {
                id: 22,
                name: 22,
            },
            {
                id: 23,
                name: 23,
            },
            {
                id: 24,
                name: 24,
            },
            {
                id: 25,
                name: 25,
            },
            {
                id: 26,
                name: 26,
            },
            {
                id: 27,
                name: 27,
            },
            {
                id: 28,
                name: 28,
            },
            {
                id: 29,
                name: 29,
            },
            {
                id: 30,
                name: 30,
            },
            {
                id: 31,
                name: 31,
            },
        ];
    };

    getBiWeeklyDay1List() {
        return [
            {
                id: 1,
                name: 1,
            },
            {
                id: 2,
                name: 2,
            },
            {
                id: 3,
                name: 3,
            },
            {
                id: 4,
                name: 4,
            },
            {
                id: 5,
                name: 5,
            },
            {
                id: 6,
                name: 6,
            },
            {
                id: 7,
                name: 7,
            },
            {
                id: 8,
                name: 8,
            },
            {
                id: 9,
                name: 9,
            },
            {
                id: 10,
                name: 10,
            },
            {
                id: 11,
                name: 11,
            },
            {
                id: 12,
                name: 12,
            },
            {
                id: 13,
                name: 13,
            },
            {
                id: 14,
                name: 14,
            },
            {
                id: 15,
                name: 15,
            },
        ];
    };

    getBiWeeklyDay2List() {
        return [
            {
                id: 16,
                name: 16,
            },
            {
                id: 17,
                name: 17,
            },
            {
                id: 18,
                name: 18,
            },
            {
                id: 19,
                name: 19,
            },
            {
                id: 20,
                name: 20,
            },
            {
                id: 21,
                name: 21,
            },
            {
                id: 22,
                name: 22,
            },
            {
                id: 23,
                name: 23,
            },
            {
                id: 24,
                name: 24,
            },
            {
                id: 25,
                name: 25,
            },
            {
                id: 26,
                name: 26,
            },
            {
                id: 27,
                name: 27,
            },
            {
                id: 28,
                name: 28,
            },
            {
                id: 29,
                name: 29,
            },
            {
                id: 30,
                name: 30,
            }
        ];
    };
}
