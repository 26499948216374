import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ECollection } from '../../enums/ECollection';
import { UtilsService } from '../utils.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';
import { UserService } from './user.service';
import { IAPIResponse } from '../../enums/types';
import { ISetDriverVehicles_P } from '../../interfaces/ISetDriverVehicles';
import { EModelApiPaths } from '../../enums/EModelApiPaths';

@Injectable({ providedIn: 'root' })
export class SetDriverVehiclesService extends BaseService {
    constructor(public http: HttpClient,
                public utilsService: UtilsService,
                public userService: UserService,
                public authService: AuthService) {
        super(http, userService, utilsService, `/schema/${environment.USER_PATH}/${environment.INS}/${environment.DB}/${ECollection.RB_SET_DRIVER_VEHICLES}`);
    }


    async masSetDriverAndVehicle<T>(body: Partial<T>) {
        const resp: IAPIResponse<ISetDriverVehicles_P> = <any>await this.http.post<T>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.MAS_SET_DRIVER_VEHICLE, body, this.userService.getRequestOptions()).toPromise();
        return resp.data;
    }
}
