import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { InvoiceSessionsService } from './services/models/Invoice-sessions.service';
import { UtilsService } from './services/utils.service';
import { DataProviderService } from './services/data-provider.service';
import { ConstantsService } from './services/constants.service';
import { UserInfoFormModelC } from './modules/user-info-form-model/user-info-form-model.c';
import { NotificationService } from './services/notification.service';
import { AuthService } from './services/models/auth.service';
import { UserService } from './services/models/user.service';
import { NavigationService } from './services/navigation.service';
import { LoginG } from './guards/login.g';
import { AdminG } from './guards/admin.g';
import { RootG } from './guards/root.g';
import { environment } from '../environments/environment';
import { RBJwtInterceptor } from './interceptor/http-rb-jwt.interceptor';
import { CustomerService } from './services/models/customer.service';
import { MasVendorFileDataService } from './services/models/mas-vendor-file-data.service';
import { DriverListService } from './services/models/driver-list.service';
import { ThirdPartySharedM } from './third-party-shared.m';
import { VehicleListService } from './services/models/vehicle-list.service';
import { RosterService } from './services/models/roster.service';
import { SetDriverVehiclesService } from './services/models/set-driver-vehicles.service';
import { UploadedDriverVehiclesService } from './services/models/uploaded-driver-vehicles.service';
import { ClaimDoneService } from './services/models/claim-done.service';
import { CycleCalendarService } from './services/models/cycle-calendar.service';
import { ClaimTrackingsService } from './services/models/claim-trackings.service';
import { PaymentTrackService } from './services/models/payment-track.service';
import { notificationsFormModelC } from './modules/header/notifications-modal/notifications-form-model.c';
import { RbNotificationService } from './services/models/rb-notification.service';
import { SocketService } from './services/socket.service';
import { SubmittedEdisService } from './services/models/submitted-edis.service';
import { BillerDashboardService } from './services/models/biller-dashboard.service';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        UserInfoFormModelC,
        notificationsFormModelC
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        ThirdPartySharedM,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: setUserFromLocalStorage,
            deps: [AuthService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RBJwtInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: setAMUserFromLocalStorage,
            deps: [UserService],
            multi: true,
        },

        // Guards
        RootG,
        AdminG,
        LoginG,

        UtilsService,
        DataProviderService,
        ConstantsService,
        NotificationService,
        AuthService,
        UserService,
        NavigationService,
        CustomerService,
        RbNotificationService,
        MasVendorFileDataService,
        SocketService,
        DriverListService,
        VehicleListService,
        RosterService,
        SetDriverVehiclesService,
        UploadedDriverVehiclesService,
        ClaimDoneService,
        CycleCalendarService,
        ClaimTrackingsService,
        PaymentTrackService,
        SubmittedEdisService,
        InvoiceSessionsService,
        BillerDashboardService
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {
}

export function setUserFromLocalStorage(authCS: AuthService) {
    return () => { // return function which returns promise.
        return new Promise<void>(async (resolve) => {
            try {
                const localStorageUser = authCS.getUserFromLocalStorage(environment.crypto.passJWT);
                if (localStorageUser) {
                    authCS.gUser = localStorageUser;
                    authCS.token.accessToken = authCS.gUser.accessToken;
                    authCS.token.refreshToken = authCS.gUser.refreshToken;
                }
            } catch (e) {
                console.log(e);
            }
            resolve();
        });
    };
}

export function setAMUserFromLocalStorage(userS: UserService) {
    return () => { // return function which returns promise.
        return new Promise<void>(async (resolve) => {
            try {
                const localStorageUser = userS.getAMUserFromLocalStorage(environment.crypto.passJWT);
                if (localStorageUser) {
                    userS.amUser = localStorageUser;
                }
            } catch (e) {
                console.log(e);
            }
            resolve();
        });
    };
}
