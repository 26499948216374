import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { EFormMode } from '../../enums/EFormMode';
import { EUserType } from '../../enums/EUserType';
import { IUser, IUser_P } from '../../interfaces/IUser';
import { ConstantsService } from '../../services/constants.service';
import { DataProviderService } from '../../services/data-provider.service';
import { AuthService } from '../../services/models/auth.service';
import { CustomerService } from '../../services/models/customer.service';
import { invoiceService } from '../../services/models/invoice.service';
import { UserService } from '../../services/models/user.service';
import { NotificationService } from '../../services/notification.service';
import { UtilsService } from '../../services/utils.service';

declare let moment: any;

@Component({
    selector: 'app-user-info-form-model',
    templateUrl: './user-info-form-model.c.html',
    styles: [`
        #my-grid tr.gold {
            background-color: #ffba80 !important;
        }
    `],
    encapsulation: ViewEncapsulation.None,
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoFormModelC implements OnInit {
    loading = { form: false };
    form: FormGroup;
    callback: Function;
    mode: EFormMode = EFormMode.ADD;
    EFormMode = EFormMode;
    EUserType = EUserType;
    paymentMethodList = [];
    weeklyList = [];
    PaymentDeductDaysList = [];
    biWeeklyDay1List = [];
    biWeeklyDay2List = [];
    selectedRows = [];
    formData: IUser_P = <any>{};
    public selectableSettings: SelectableSettings = { mode: 'single' };
    tabIndex: number = 0;
    autoPaymentDeduct: boolean = false;
    paymentMethodId: string = '';
    customerId: string = '';
    pass: string = '';
    paymentPeriod: string = '';
    paymentMethodType: string = '';
    paymentDeductDate: number = null;
    weekly: string = '';
    biWeeklyDay1: number = null;
    biWeeklyDay2: number = null;

    // @ts-ignore
    @ViewChild('requestTabStrip') public requestTabStrip: TabStripComponent;

    constructor(private fb: FormBuilder,
                private ns: NotificationService,
                private userService: UserService,
                public authService: AuthService,
                public customerService: CustomerService,
                public dataProviderService: DataProviderService,
                public cs: ConstantsService,
                public invoiceService: invoiceService,
                public utilsService: UtilsService,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.createForm();
        this.weeklyList = this.dataProviderService.getWeeklyList();
        this.PaymentDeductDaysList = this.dataProviderService.getMonthDaysList();
        this.biWeeklyDay1List = this.dataProviderService.getBiWeeklyDay1List();
        this.biWeeklyDay2List = this.dataProviderService.getBiWeeklyDay2List();
    }

    // Use an arrow function to capture the 'this' execution context of the class.
    public rowCallback = (context: RowClassArgs) => {
        if (context.dataItem.id === this.paymentMethodId) return { gold: true };
    };


    async paymentTabSelected(event: SelectEvent) {
        if (!event) return;
        this.tabIndex = event.index;
        // if (this.tabIndex === 0)
        if (this.tabIndex === 1) await this.loadPaymentMethod();
    }

    async loadPaymentMethod() {
        this.paymentMethodList = [];
        const res = await this.invoiceService.stripeGetPaymentMethodList({ _id: this.authService.getUserId() });
        this.paymentMethodList = res.data;
        this.cd.detectChanges();
    }

    createForm() {
        this.form = this.fb.group(<IUser_P>{
            email: <any>[null, [Validators.required, Validators.email]],
            name: <any>[null, [Validators.required]],
            mobileNo: <any>[null, [Validators.required]],
            password: <any>[null, [Validators.required]],
        });
    }

    async showModal(mode: string, dataItem: IUser) {
        if (this.authService.gUser.userType === EUserType.CLIENT) this.requestTabStrip.selectTab(0);
        $(`#idUserInfoFormModel`).modal('show');
        this.formData = dataItem;
        await this.init(mode);
    }

    hideModal() {
        $(`#idUserInfoFormModel`).modal('hide');
    }

    async init(mode) {
        this.loading.form = true;
        this.form.enable();
        if (mode === EFormMode.EDIT) {
            this.mode = EFormMode.EDIT;
            this.form.reset();
            await this.fillUserDataInForm();
            this.autoPaymentDeduct = this.formData.autoPaymentDeduct;
            this.paymentMethodId = this.formData.paymentMethodId;
            this.customerId = this.formData.customer_id;
            this.paymentPeriod = this.formData.paymentPeriod;
            this.paymentMethodType = this.formData.paymentMethodType;
            this.paymentDeductDate = this.formData.paymentDeductDate;
            this.weekly = this.formData.weekly;
            this.biWeeklyDay1 = this.formData.biWeeklyDay1;
            this.biWeeklyDay2 = this.formData.biWeeklyDay2;
        } else {
            this.formData = undefined;
            this.mode = EFormMode.ADD;
            this.form.reset();
        }
        this.loading.form = false;
        this.cd.detectChanges();
    }

    async apply(event: any, dataItem: any) {
        this.selectedRows = [];
        let resp = await this.userService.updateUser(this.authService.getUserId(), { paymentMethodId: dataItem.id }, { select: '_id' });
        if (resp) this.paymentMethodId = dataItem.id;
        // this.selectedRows = [dataItem.id]
        this.userService.gUser.next({ action: 'REFRESH_G_USER' });
        this.cd.detectChanges();
    }

    async save() {
        const formData: IUser_P = this.form.getRawValue();
        formData._id = this.formData._id;
        if (formData.name) delete formData.name;
        if (formData.email) delete formData.email;
        if (this.authService.gUser.userType === EUserType.CLIENT) {
            formData.autoPaymentDeduct = this.autoPaymentDeduct;
            formData.paymentMethodId = this.paymentMethodId;
            formData.paymentPeriod = this.paymentPeriod;
            formData.paymentMethodType = this.paymentMethodType;
            formData.paymentDeductDate = this.paymentDeductDate;
            formData.weekly = this.weekly;
            formData.biWeeklyDay1 = this.biWeeklyDay1;
            formData.biWeeklyDay2 = this.biWeeklyDay2;

            if (formData.paymentPeriod === 'paymentDeductDate') {
                formData.biWeeklyDay1 = null;
                formData.biWeeklyDay2 = null;
                formData.weekly = null;

            } else if (formData.paymentPeriod === 'weeklyPayment') {
                formData.paymentDeductDate = null;
                formData.biWeeklyDay1 = null;
                formData.biWeeklyDay2 = null;

            } else if (formData.paymentPeriod === 'biWeeklyPayment') {
                formData.paymentDeductDate = null;
                formData.weekly = null;
            }
        }
        try {
            this.loading.form = true;
            const pass = this.form.get('password').value;
            if (formData._id) {
                if (pass) formData.password = pass;
                else delete formData.password;
            }
            const res = await this.userService.updateUser<IUser_P>(formData._id, formData, { select: '_id' });
            if (res) {
                let getCompany = <any>await this.customerService.getById(this.authService.gUser.companyId);
                if (getCompany && getCompany._id) {

                    let paymentDeductDate = this.paymentDeductDate;
                    let biWeeklyDay1 = this.biWeeklyDay1;
                    let biWeeklyDay2 = this.biWeeklyDay2;
                    let weekly = this.weekly;

                    if (this.paymentPeriod === 'paymentDeductDate') {
                        biWeeklyDay1 = null;
                        biWeeklyDay2 = null;
                        weekly = null;

                    } else if (this.paymentPeriod === 'weeklyPayment') {
                        paymentDeductDate = null;
                        biWeeklyDay1 = null;
                        biWeeklyDay2 = null;

                    } else if (this.paymentPeriod === 'biWeeklyPayment') {
                        paymentDeductDate = null;
                        weekly = null;
                    }

                    let obj = {
                        accountInformation: {
                            userName: this.form.get('email').value,
                            password: this.form.get('password').value ? this.form.get('password').value : getCompany.accountInformation.password,
                            autoPaymentDeduct: this.autoPaymentDeduct,
                            paymentMethodId: this.paymentMethodId,
                            customer_id: this.customerId,
                            paymentPeriod: this.paymentPeriod,
                            paymentMethodType: this.paymentMethodType,
                            paymentDeductDate: paymentDeductDate,
                            biWeeklyDay1: biWeeklyDay1,
                            biWeeklyDay2: biWeeklyDay2,
                            weekly: weekly
                        }
                    };

                    await this.customerService.put(getCompany._id, obj);
                }
                this.ns.showSuccess('User info updated successfully.');
                this.hideModal();
                if (!pass) this.userService.gUser.next({ action: 'REFRESH_G_USER' });
                if (pass) {
                    setTimeout(() => {
                        this.authService.logout();
                    }, 300);
                }
            }
            this.loading.form = false;
        } catch (e) {
            this.loading.form = false;
            this.ns.showErrorFromResponse(e.error || e, 'Unable to save user.');
        }
    }

    async fillUserDataInForm() {
        try {
            this.loading.form = true;
            this.form.reset();
            const resp = await this.userService.getByIdUser<IUser_P>(this.authService.gUser._id);
            this.pass = resp.password;
            this.form.patchValue(resp);
            this.form.get('password').setValidators([]);
            this.form.get('name').disable();
            this.form.get('email').disable();
            this.form.get('password').updateValueAndValidity();
            this.form.get('password').setValue('');
            this.loading.form = false;
        } catch (e) {
            this.loading.form = false;
            this.ns.showErrorFromResponse(e.error || e, 'Unable to fill user data.');
        }
    }
}
