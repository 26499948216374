import { Injectable } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

declare const CryptoJS: any;

@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    constructor() {
    }

    public generateMongoObjectId() {
        const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    }

    characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

    public getRandomString(size: number) {
        let result = '';
        const charactersLength = this.characters.length;
        for (let i = 0; i < size; i++) result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
        return result;
    }

    getEncryptedString(obj: any, p: string) {
        try {
            if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'number') return CryptoJS.AES.encrypt(obj, p).toString();
            else return CryptoJS.AES.encrypt(JSON.stringify(obj), p).toString();
        } catch (e) {
            console.log(e);
        }
    }

    public getDecryptedObject(encryptedStr: string, p: string) {
        let obj;
        try {
            const decrypted = CryptoJS.AES.decrypt(encryptedStr, p);
            const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
            if (decryptedStr) obj = JSON.parse(decryptedStr);
        } catch (e) {
            console.log(e);
        }
        return obj;
    }

    public getDecryptedString(encryptedStr: string, p: string) {
        const decrypted = CryptoJS.AES.decrypt(encryptedStr, p);
        try {
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (e) {
            console.log(e);
        }
    }

    public toAmPmTrips(what: any) {
        if (!what) {
            return ' ';
        }
        if (what.length === 0) {
            return ' ';
        }
        if (what !== null) {
            if (what.toLowerCase() !== 'call' && what.length > 0) {
                if (what.indexOf(':') < 0) {
                    what = what.substring(0, 2) + ':' + what.substring(2, 4);
                }
                // console.log(what);
                let ap = 'am';
                const arr = what.split(':');
                let h = parseInt(arr[0]);
                if (h > 11) {
                    ap = 'pm';
                }
                if (h > 12) {
                    h = h - 12;
                }
                if (h === 0) {
                    h = 12;
                }
                return h.toString().concat(':').concat(arr[1]).concat(ap);
            } else {
                return what;
            }
        } else {
            return '';
        }
    }

    tripLegStatus(trip) {
        let stat = '';
        if (parseInt(trip.vendorAttestation) === 1) {
            stat = 'Leg Active';
        } else if (parseInt(trip.vendorAttestation) === 2) {
            stat = 'Leg Deleted';
        } else {
            if (
                trip.GPS.noshowTimestamp.length > 0
                || parseInt(trip.vendorAttestation) === 3
                || parseInt(trip.existingActions.attested) === 3
            ) {
                stat = 'Leg No Show';
            } else {
                if (this.isCancelledFilter(trip.status)) {
                    stat = 'Leg Deleted';
                } else {
                    stat = 'Leg Active';
                }
            }
        }
        return stat;
    }

    isCancelledFilter(tripStatus) {
        let tf = false;
        tripStatus = tripStatus.toLowerCase();
        const arr = ['cancel'];
        if (tripStatus.indexOf(arr[0]) > -1) {
            tf = true;
        }
        return tf;
    }

    dollarAmountToWords(n: any) {
        let op;
        let nums = n.toString().split('.');
        let whole = this.dollarAmount(nums[0]);
        if (nums[1] == null) nums[1] = 0;
        if (nums[1].length == 1) nums[1] = nums[1] + '0';
        if (nums[1].length > 2) {
            nums[1] = nums[1].substring(2, nums[1].length - 1);
        }
        if (nums.length == 2) {
            if (nums[0] <= 12) {
                nums[0] = nums[0] * 10;
            } else {
                nums[0] = nums[0];
            }
            let fraction = this.dollarAmount(nums[1]);
            if (whole == '' && fraction == '') {
                op = 'Zero Only';
            }
            if (whole == '' && fraction != '') {
                op = fraction + 'Cent Only';
            }
            if (whole != '' && fraction == '') {
                op = whole + 'Dollar Only';
            }
            if (whole != '' && fraction != '') {
                op = `${whole}Dollar And ${fraction}Cent Only`;
            }
            if (n > 99999999999.99) {
                op = 'Oops!!! The amount is too big to convert';
            }
            if (isNaN(n) == true) {
                op = 'Error : Amount in number appears to be incorrect';
            }
            return op;
        }
    }

    dollarAmount(amount) {
        let words = new Array();
        words[0] = 'Zero';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        words[100] = 'One Hundred';
        words[200] = 'Two Hundred';
        words[300] = 'Three Hundred';
        words[400] = 'Four Hundred';
        words[500] = 'Five Hundred';
        words[600] = 'Six Hundred';
        words[700] = 'Seven Hundred';
        words[800] = 'Eight Hundred';
        words[900] = 'Nine Hundred';
        amount = amount.toString();
        let atemp = amount.split('.');
        let number = atemp[0].split(',').join('');
        let n_length = number.length;
        let words_string = '';
        if (n_length <= 11) {
            let n_array = <any>new Array(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
            let received_n_array = new Array();
            for (let i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (let i = 11 - n_length, j = 0; i < 11; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (let i = 0, j = 1; i < 11; i++, j++) {
                if (i == 0 || i == 3 || i == 6 || i == 9) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            let value: any = '';
            for (let i = 0; i < 11; i++) {
                if (i == 0 || i == 3 || i == 6 || i == 9) {
                    value = n_array[i] * 10;
                } else if (i == 2 || i == 5 || i == 8) {
                    value = n_array[i] * 100;
                } else {
                    value = n_array[i];
                }

                if (value != 0) {
                    words_string += words[value] + ' ';
                }
                if ((i == 1 && value != 0) && (n_array[i - 1] > 0)) {
                    words_string += 'Billion ';
                } else if ((i == 1) && value != 0) {
                    words_string += 'Biillion ';
                }
                if ((i == 4) && value == 0 && (n_array[i - 1] > 0 || n_array[i - 2] > 0)) {
                    words_string += 'Million ';
                } else if ((i == 4) && value != 0) {
                    words_string += 'Million ';
                }
                if ((i == 7) && value == 0 && (n_array[i - 1] > 0 || n_array[i - 2] > 0)) {
                    words_string += 'Thousand ';
                } else if ((i == 7) && value != 0) {
                    words_string += 'Thousand ';
                }
            }
            words_string = words_string.split(' ').join(' ');
        }
        return words_string;
    }

    truncateDecimals(num: number, digits: number) {
        if (num) {
            let numS = num.toString(),
                decPos = numS.indexOf('.'),
                substrLength = decPos === -1 ? numS.length : 1 + decPos + digits,
                trimmedResult = numS.substr(0, substrLength),
                finalResult = isNaN(<any>trimmedResult) ? 0 : trimmedResult;

            return parseFloat(<any>finalResult);
        }
        return 0;
    }

    /**
     * It will combine CSV file data.
     * It will take first line (header line) only from first file, it will ignore first line from other files.
     * @param files
     */
    public async getFileContentFromFiles(files: File[]): Promise<string> {
        let lines: string[] = [];
        let first = true;
        for (const file of files) {
            const content = await this.getFileContentFromFile(file);
            const linesTemp = content.split('\n');

            if (first) lines.push(...linesTemp);
            else {
                linesTemp.shift(); // remove header from 2nd, 3rd files.
                lines.push(...linesTemp);
            }
            first = false;
        }
        return lines.join('\n');
    }

    public getFileContentFromFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt: any) {
                resolve(evt.target.result);
            };
            reader.onerror = function (evt) {
                reject(new Error('Unable to read file.'));
            };
        });
    }

    isSame(f1: number, f2: number) {
        return Math.abs(f1 - f2) < 0.001
    }
}
