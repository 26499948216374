import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/models/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
    providedIn: 'root',
})
export class LoginG implements CanActivate {
    constructor(
        private navigationS: NavigationService,
        private authService: AuthService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.gUser) {
            return true;
        } else {
            this.navigationS.gotoDashboard(this.authService.gUser);
        }
    }
}
