import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ECollection } from '../../enums/ECollection';
import { UtilsService } from '../utils.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class SubmittedEdisService extends BaseService {
    constructor(public http: HttpClient, public utilsService: UtilsService, public userService: UserService, public authService: AuthService) {
        super(http, userService, utilsService, `/schema/${environment.USER_PATH}/${environment.INS}/${environment.DB}/${ECollection.RB_SUBMITTED_EDIS}`);
    }
}
