import { Pipe, PipeTransform } from '@angular/core';
import { ConstantsService } from '../services/constants.service';

declare let moment: any;

// noinspection AngularMissingOrInvalidDeclarationInModule
@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
    constructor(
        public cs: ConstantsService,
    ) {
    }

    transform(dateString: any, format?: string): any {
        if (!dateString) return '';
        const moment1 = moment(dateString);
        if (format) return moment1.format(format);
        else return moment1.format(this.cs.DATETIME_FORMAT_USER);
    }
}
