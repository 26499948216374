import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/models/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'nemt-billing-eight';

    constructor(public authService: AuthService, router: Router) {
        this.authService.router = router;
    }
}
