import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { BodyModule, ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ConfirmationDialogC } from './modules/confirmation-dialog/confirmation-dialog.c';
import { MomentPipe } from './pipes/moment.pipe';
import { NumberWithCommaPipe } from './pipes/number-with-comma.pipe';
import { SaveNoDblClickDirective } from './directives/save-no-dbl-click.directive';

const modules = [
    LayoutModule,
    InputsModule,
    DropDownListModule,
    MultiSelectModule,
    DropDownsModule,
    GridModule,
    BodyModule,
    ButtonsModule,
    DialogModule,
    TooltipModule,
    PopupModule,
    SortableModule,
    PDFModule,
    DateInputsModule,
    ExcelModule,
    PDFExportModule,

    // angular
    ReactiveFormsModule,
    FormsModule,
];

const directives = [
    SaveNoDblClickDirective,
];

const pipes = [
    MomentPipe,
    NumberWithCommaPipe,
];

const components = [
    ConfirmationDialogC,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ...modules,

    ],
    declarations: [...pipes, ...components, ...directives],
    exports: [
        CommonModule,
        ...modules,
        ...pipes,
        ...components,
        ...directives
    ],
})
export class ThirdPartySharedM {
}
