import { EModelApiPaths } from '../app/enums/EModelApiPaths';

export const environment = {
    production: true,
    RB_BE_HOST_PORT: 'https://qa-api.billing-nemt.com',
    USER_PATH: 'admin',
    INS: 'mongodb',
    DB: 'nemt_billing',
    authTokenInfo: 'nemt_billing_authorization', // it will be secret path.
    RB_BE_WS_HOST_PORT: 'wss://qa-ws.billing-nemt.com', // 'ws://localhost:38245'
    IS_ENCRYPTED: false,
    ENV: 'PROD', // DEV | QA | PROD,
    STRIP_CHARGES_FOR_CARD: 2.9, // 2.9%
    STRIP_FIX_CHARGE: 0.30,      // 0.30
    STRIP_CHARGES_FOR_BANK: 0.8, // 0.8%

    crypto: {
        passJWT: '54d3951e-e2db-4312-9718-55e81daa3954', // it is secretFETransfer from secret
    },

    get RB_BE_HOST_PORT_SITES() {
        return this.RB_BE_HOST_PORT + `${EModelApiPaths.API}`;
    },
};
