import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ECollection } from '../../enums/ECollection';
import { EModelApiPaths } from '../../enums/EModelApiPaths';
import { IAPIResponse } from '../../enums/types';
import { UtilsService } from '../utils.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
    constructor(public http: HttpClient, public utilsService: UtilsService, public userService: UserService, public authService: AuthService) {
        super(http, userService, utilsService, `/schema/${environment.USER_PATH}/${environment.INS}/${environment.DB}/${ECollection.CUSTOMERS}`);
    }

    async masTranProviderInfo(body: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.MAS_TRANSPORTATION_PROVIDER_INFO, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }

    async syncAllBaseDriverAndVehicle(body: any) {
        const encryptedData = this.encryptedPayload(body);
        const resp: IAPIResponse<any> = await this.http.post<IAPIResponse<any>>(environment.RB_BE_HOST_PORT_SITES + EModelApiPaths.CUSTOM_API_BASE + `/${environment.USER_PATH}` + EModelApiPaths.MAS_SYNC_ALL_BASE_DRIVER_AND_VEHICLE, encryptedData, this.userService.getRequestOptions()).toPromise();
        if (this.isEncrypted) return this.utilsService.getDecryptedObject(resp.encryptedData, environment.crypto.passJWT);
        else return resp.data;
    }


}
