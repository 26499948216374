import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'upload-file-process',
        loadChildren: () => import('./modules/upload-file-process/upload-file-process.module').then(m => m.UploadFileProcessModule),
    },
    {
        path: 'daycare',
        loadChildren: () => import('./modules/daycare/daycare.module').then(m => m.DaycareModule),
    },
    {
        path: 'driver-list',
        loadChildren: () => import('./modules/driver-list/driver-list.module').then(m => m.DriverListModule),
    },
    {
        path: 'vehicle-list',
        loadChildren: () => import('./modules/vehicle-list/vehicle-list.module').then(m => m.VehicleListModule),
    },
    {
        path: 'uploaded-driver-vehicle-list',
        loadChildren: () => import('./modules/uploaded-driver-vehicle-list/uploaded-driver-vehicle-list.module').then(m => m.UploadedDriverVehicleListModule),
    },
    {
        path: 'invoice',
        loadChildren: () => import('./modules/invoice/invoice.module').then(m => m.InvoiceModule),
    },
    {
        path: 'transportation',
        loadChildren: () => import('./modules/transportation/transportation.module').then(m => m.TransportationModule),
    },
    {
        path: 'my-account',
        loadChildren: () => import('./modules/my-account/my-account.module').then(m => m.MyAccountModule),
    },
    {
        path: 'trip-management',
        loadChildren: () => import('./modules/trip-management/trip-management.module').then(m => m.TripManagementModule),
    },
    {
        path: 'admin-list',
        loadChildren: () => import('./modules/admin-manage/admin-manage.module').then(m => m.AdminManageModule),
    },
    {
        path: 'biller-list',
        loadChildren: () => import('./modules/biller-manage/biller-manage.module').then(m => m.BillerManageModule),
    },
    {
        path: 'permission',
        loadChildren: () => import('./modules/biller-permissions/biller-permissions.module').then(m => m.BillerPermissionsModule),
    },
    {
        path: 'track-payment',
        loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule),
    },
    {
        path: 'cycle-calendar',
        loadChildren: () => import('./modules/cycle-calendar/cycle-calendars.module').then(m => m.CycleCalendarsModule),
    },
    {
        path: 'summary-report',
        loadChildren: () => import('./modules/summary-report/summary-report.module').then(m => m.SummaryReportModule),
    },
    {
        path: 'details-report',
        loadChildren: () => import('./modules/details-report/details-report.module').then(m => m.DetailsReportModule),
    },
    {
        path: 'eligibility-report',
        loadChildren: () => import('./modules/eligibility-report/eligibility-report.module').then(m => m.EligibilityReportModule),
    },
    {
        path: 'claim-trackings',
        loadChildren: () => import('./modules/claim-trackings/claim-trackings.module').then(m => m.ClaimTrackingsModule),
    },
    {
        path: 'eligibility-trackings',
        loadChildren: () => import('./modules/eligibility-trackings/eligibility-trackings.module').then(m => m.EligibilityTrackingsModule),
    },
    {
        path: 'payment/:paymentMode',
        loadChildren: () => import('./modules/payment-info/payment-info.m').then(m => m.PaymentInfoM),
    },
    {
        path: 'biller-dashboard',
        loadChildren: () => import('./modules/dashboard/biller-dashboard/biller-dashboard.module').then(m => m.BillerDashboardModule),
    },
    {
        path: 'client-dashboard',
        loadChildren: () => import('./modules/dashboard/client-dashboard/client-dashboard.module').then(m => m.ClientDashboardModule),
    },
    {
        path: 'admin-dashboard',
        loadChildren: () => import('./modules/dashboard/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
